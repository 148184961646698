<template>
  <ion-content
    :scroll-y="true"
    class="ion-padding"
  >
    <div v-if="selectedView === 1">
      <i
        class="mdi mdi-map-marker-radius text--primary"
        style="font-size: 80px"
      />
      <div
        v-if="role !== 'Landlord'"
        class="text--white mt-30 text-14"
      >
        {{ $t('permissionRequest') }}
      </div>
      <div
        v-else
        class="text--white mt-30 text-14"
      >
        {{ $t('permissionRequestLandlord') }}
      </div>
    </div>
  </ion-content>
  <ion-footer
    no-border
    transparent
    class="footer"
  >
    <ion-button
      v-if="selectedView === 1"
      color="primary"
      @click="askPermissions"
    >
      <ion-label class="text--dark">
        {{ $t('Continue') }}
      </ion-label>
    </ion-button>
    <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover"
    >
      <ion-label color="primary">
        {{ $t('close') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'
import { AndroidPermissions } from '@ionic-native/android-permissions'

export default defineComponent({
  name: 'Popover',
  components: { IonContent },
  props: {
    requestPermission: { type: Function, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      selectedView: 1,
      loading: false,
      deviceHistory: [],
      androidPermissions: AndroidPermissions
    }
  },
  computed: {
    role () {
      return this.$store.state.userRole
    }
  },
  created () {
  },
  methods: {
    askPermissions () {
      this.androidPermissions.requestPermissions(
        [
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
          'android.permission.ACCESS_BACKGROUND_LOCATION'
        ]
      ).then(
        this.requestPermission(),
        this.close()
      )
    },
    dismissPopover () {
      this.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.buttonRoom {
  padding-left: 2px;
  padding-right: 2px;
}
.inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
    max-height: 44px;
}
.active {
    --background: #3F424A;
    color: colors(primary, base);
    max-height: 44px;
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}
.light-intensity {
  ion-range {
    --bar-background: linear-gradient(to right, black 0%, white 100%) !important;
  }
}
.color-temperature {
  ion-range {
    --bar-background: linear-gradient(to right, white 0%, lightyellow, yellow, orange 100%) !important;
  }
}
.range.range {
  --bar-background-active: transparent;
  --knob-size: 24px;
  padding-left: 13px;
  padding-right: 13px;
  --bar-border-radius: 10px !important;
  --bar-height: 6px !important;
  padding-top: 0px !important;
}
</style>
