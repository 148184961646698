<template>
  <ion-page content-id="main-page">
    <ion-tabs
      class="ion-no-border"
    >
      <ion-tab-bar
        v-if="userRole !== 'Landlord'"
        slot="bottom"
        class="tabs"
      >
        <ion-button
          color="transparent"
          class="my-home button"
          fill="clear"
          :class="currentRouteName === 'MainView' ? 'active' : 'inactive'"
          tab="main"
          @click="() => {router.push('/main-view'); selectedButton = 'main-view'}"
        >
          <i class="material-icons house">house</i>
          <ion-text>{{ $t('myHome') }}</ion-text>
        </ion-button>

        <ion-button
          color="transparent"
          class="button"
          fill="clear"
          :class="currentRouteName === 'Security' ? 'active' : 'inactive'"
          tab="security"
          @click="() => {router.push('/security'); selectedButton = 'security'}"
        >
          <i class="material-icons icons">verified_user</i>
          <ion-text>{{ $t('security') }}</ion-text>
        </ion-button>

        <ion-button
          color="transparent"
          class="button"
          fill="clear"
          :class="currentRouteName === 'Automation' ? 'active' : 'inactive'"
          tab="automation"
          @click="() => {router.push('/automation'); selectedButton = 'automation'}"
        >
          <i class="material-icons icons">model_training</i>
          <ion-text>{{ $t('automation') }}</ion-text>
        </ion-button>

        <ion-button
          color="transparent"
          class="button"
          fill="clear"
          :class="currentRouteName === 'eShop' ? 'active' : 'inactive'"
          tab="e-shop"
          @click="() => {router.push('/e-shop'); selectedButton = 'e-shop'}"
        >
          <i class="material-icons icons">shopping_cart</i>
          <ion-text>{{ $t('shop') }}</ion-text>
        </ion-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { useBackButton, useIonRouter, toastController, popoverController } from '@ionic/vue'
import { FCM } from '@capacitor-community/fcm'
import { useRouter } from 'vue-router'
import { App } from '@capacitor/app'
import { PushNotifications } from '@capacitor/push-notifications'
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'
import { Geolocation } from '@ionic-native/geolocation/'
import { AndroidPermissions } from '@ionic-native/android-permissions'

import permissionPopover from '../components/Popovers/AndroidPermissionPopOver.vue'

export default {
  data () {
    const router = useRouter()
    const ionRouter = useIonRouter()
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp()
      }
    })
    return {
      fcm: FCM,
      router,
      selectedButton: 'main-view',
      geolocation: Geolocation,
      androidPermissions: AndroidPermissions,
      userRole: ''
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    currentRouteName () {
      if (this.$route.name === 'Security') {
        this.$store.dispatch('getAlarmStatus')
      }
      return this.$route.name
    },
    userData () {
      return this.$store.state.userData[0]
    }
  },
  created () {
    const userRole = this.$store.state.userRole
    this.userRole = userRole
    if (userRole !== 'Landlord' && this.$store.getters.loggedIn) {
      this.$store.dispatch('getUserData').then(response => {
        this.$i18n.locale = this.$store.state.language
        // console.log(this.$i18n.locale)
      })
      console.log('request location')
    } else if (userRole === 'Landlord' && this.$store.getters.loggedIn) {
      this.$store.dispatch('getLandlordBuildingsAndProperties').then(resp => {
        this.$i18n.locale = this.$store.state.language
      })
    }
    this.$store.dispatch('connectSocketIo')
    if (this.platform.includes('capacitor') && this.$store.getters.loggedIn) {
      PushNotifications.requestPermissions().then(async result => {
        console.log('result ' + JSON.stringify(result))
        await PushNotifications.register()
      })
      PushNotifications.addListener('registrationError', (error) => {
        console.log(`error on register ${JSON.stringify(error)}`)
      })
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log(`notification ${JSON.stringify(notification)}`)
      })
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        this.$router.push({ path: '/' })
      }
      )
      this.fcm.getToken().then(data => {
        console.log('FCM Token ' + JSON.stringify(data))
        this.$store.dispatch('notificationToken', data)
      })
      this.$store.dispatch('connectSocketIo')
    }
    if (this.platform.includes('android')) {
      console.log('android')
      // const list = [
      //   this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
      //   this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
      // ]
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => {
          console.log('Android status permissions')
          console.log('PermissionResult          ' + JSON.stringify(result))
          if (!result.hasPermission) {
            this.openPermissionPopup()
          } else {
            this.requestPermissions()
          }
        },
        err => {
          console.log(err)
        }
      )
    } else {
      this.requestPermissions()
    }
  },
  mounted () {
  },
  methods: {
    async openToast (toastMessage, cssClass) {
      const toast = await toastController
        .create({
          message: toastMessage,
          cssClass: cssClass,
          duration: 2000
        })
      return toast.present()
    },
    async openPermissionPopup () {
      const popover = await popoverController
        .create({
          component: permissionPopover,
          cssClass: 'popover',
          translucent: false,
          componentProps: {
            close: () => popover.dismiss(),
            requestPermission: () => this.requestPermissions()
          }
        })
      await popover.present()
    },
    async requestPermissions () {
      this.geolocation.getCurrentPosition().then((resp) => {
        this.$store.commit('initLocation', { lat: resp.coords.latitude, lng: resp.coords.longitude })
        BackgroundGeolocation.onGeofence(geofence => {
          console.log('[geofence] ', geofence)
        })
        BackgroundGeolocation.ready({
          url: 'https://services.homevision.se/public/geofence',
          autoSync: true,
          stopOnTerminate: false,
          startOnBoot: true,
          geofenceModeHighAccuracy: true,
          headers: {
            'our-super-auth-tks': 'ZYSMKSUAnsjks123ssjkd08120389ksdjga'
          }
        })
          .then(state => {
            console.log('Fence enbaled' + this.userData.geofenceEnabled)
            if (this.userData.geofenceEnabled) {
              console.log('Starting geofences')
              BackgroundGeolocation.startGeofences()
            }
          })
      })
        .catch((error) => {
          console.log('Error getting location', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
ion-tab-bar {
  --background: none;
  border: 0;
  padding-top: 10px;
  padding-bottom: 20px;
  justify-content: space-between;
}

.button {
  flex-direction: row;
  flex: 0;
  padding: 0;
  height: 40px;
  ion-text {
    font-size: 14px;
    color: white;
    margin-left: 5px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.6px;
  }
  .icons {
    color: #d9e1d977;
    font-size:28px;
  }
  .house {
    color: #d9e1d977;
    font-size: 32px;
  }
}

.inactive {
  ion-text {
    display: none;
  }
}

.active {
  background: rgba(44, 48, 54, 0.3);
  border-radius: 5px;
  flex: 1;
  &:first-of-type {
    margin-left:10px;
  }
  &:last-of-type {
    margin-right: 10px;
  }
  ion-text {
    display: block;
  }
  .icons {
    color: #FFD4A4;
    font-size: 28px;
  }
  .house {
    color: #FFD4A4;
    font-size: 34px;
  }
}
</style>
